import React, { useState } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import { PhotoSwipe } from 'react-photoswipe'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { pttStyles, demoStyles, demoLinkStyles, jumpLinkStyles } from './styles/ptt-styles'
import UpArrow from '../images/up_arrow.svg'

import { setUpFiles, getImage, getVideo } from '../utils/getMedia'

const propTypes = {
  data: PropTypes.object.isRequired,
}

const demoProptypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  linkType: PropTypes.string,
}

const Demo = props => {
  const { url, title, linkType } = props
  return (
    <p>
      <a href={url} css={demoLinkStyles}>
        View {title} {linkType}
      </a>
    </p>
  )
}

Demo.propTypes = demoProptypes

const pttTemplate = props => {
  const [isOpen, setIsOpen] = useState()
  const project = props.data.contentfulProject
  const { projectDetails } = project
  const staticFiles = props.data.allFile.nodes
  setUpFiles(staticFiles)

  const openPhotoSwipe = (e, galleryIndex) => {
    e.preventDefault()
    setIsOpen(galleryIndex)
  }

  const handleClose = () => {
    setIsOpen(null)
  }

  const options = {
    history: false,
  }

  const gallery1Items = [
    {
      src: getImage('concept-1-high-level-2').publicURL,
      w: 2798,
      h: 1302,
      title: 'High Level Concepts 2',
    },
    {
      src: getImage('concept-1-high-level-3').publicURL,
      w: 2792,
      h: 868,
      title: 'High Level Concepts 3',
    },
    {
      src: getImage('concept-1-high-level-4').publicURL,
      w: 2796,
      h: 648,
      title: 'High Level Concepts 4',
    },
    {
      src: getImage('concept-1-high-level-5').publicURL,
      w: 2786,
      h: 472,
      title: 'High Level Concepts 5',
    },
  ]

  const gallery2Items = [
    {
      src: getImage('concept-9-dual-channels-1').publicURL,
      w: 2574,
      h: 1460,
      title: 'Dual Channels Concepts 1',
    },
    {
      src: getImage('concept-9-dual-channels-2').publicURL,
      w: 2784,
      h: 960,
      title: 'Dual Channels Concepts 2',
    },
    {
      src: getImage('concept-9-dual-channels-3').publicURL,
      w: 2788,
      h: 676,
      title: 'Dual Channels Concepts 3',
    },
    {
      src: getImage('concept-9-dual-channels-4').publicURL,
      w: 2786,
      h: 764,
      title: 'Dual Channels Concepts 4',
    },
  ]

  const gallery3Items = [
    {
      src: getImage('concept-20-history-search-1').publicURL,
      w: 2786,
      h: 706,
      title: 'History &amp; Search Concepts 1',
    },
    {
      src: getImage('concept-20-history-search-2').publicURL,
      w: 2370,
      h: 1404,
      title: 'History &amp; Search Concepts 2',
    },
    {
      src: getImage('concept-20-history-search-3').publicURL,
      w: 2404,
      h: 718,
      title: 'History &amp; Search Concepts 3',
    },
    {
      src: getImage('concept-20-history-search-4').publicURL,
      w: 2284,
      h: 1384,
      title: 'History &amp; Search Concepts 4',
    },
  ]

  const gallery4Items = [
    {
      src: getImage('concept-17-top-control-1').publicURL,
      w: 2620,
      h: 1464,
      title: 'Top Control Concepts 1',
    },
    {
      src: getImage('concept-17-top-control-2').publicURL,
      w: 2406,
      h: 826,
      title: 'Top Control Concepts 2',
    },
    {
      src: getImage('concept-17-top-control-3').publicURL,
      w: 2704,
      h: 832,
      title: 'Top Control Concepts 3',
    },
  ]

  const gallery5Items = [
    {
      src: getImage('concept-7-zones-channels-1').publicURL,
      w: 2318,
      h: 624,
      title: 'Zones &amp; Channels Concepts 1',
    },
    {
      src: getImage('concept-7-zones-channels-2').publicURL,
      w: 2308,
      h: 618,
      title: 'Zones &amp; Channels Concepts 2',
    },
    {
      src: getImage('concept-7-zones-channels-3').publicURL,
      w: 2692,
      h: 630,
      title: 'Zones &amp; Channels Concepts 3',
    },
    {
      src: getImage('concept-7-zones-channels-4').publicURL,
      w: 1854,
      h: 626,
      title: 'Zones &amp; Channels Concepts 4',
    },
    {
      src: getImage('concept-7-zones-channels-5').publicURL,
      w: 2314,
      h: 626,
      title: 'Zones &amp; Channels Concepts 5',
    },
    {
      src: getImage('concept-7-zones-channels-6').publicURL,
      w: 3505,
      h: 1129,
      title: 'Zones &amp; Channels Concepts 6',
    },
  ]

  return (
    <Layout unstyled>
      <SEO title={project.title} />
      <div css={pttStyles} id="ptt" className="scrolling ptt">
        <section className="main_header">
          <div className="header_text">
            <img src={getImage('front_logo').publicURL} alt="" width="271" />
            <span>A tool for mission</span>
            <span>critical communcation</span>
          </div>
          <div className="phone">
            <img src={getImage('front_phone').publicURL} alt="" width="370" />
          </div>
        </section>

        <section id="phase_one" className="">
          <header className="title">
            <h1>Phase One: Unified PTT</h1>
          </header>
          <div className="one">
            <p>
              Unified Push-To-Talk for public safety is a solution that extends ASTRO radio to broadband, enables communication between
              radio and mobile broadband users. It is for those who are traveling outside the radio coverage area, yet stay connected and
              engage remotely when needed.
            </p>
            <p>
              Unified PTT offers the productivity advantage of instand PTT service directly from their mobile phone with an easy to use an
              Android app designed to provide a radio-like user experience.
            </p>
          </div>
          <div className="forms">
            <img src={getImage('PTT_1').publicURL} alt="" />
          </div>
        </section>

        <section id="high-level-structure" className="grey">
          <header className="title">
            <h1>High Level Structure</h1>
          </header>
          <div className="forms">
            <img src={getImage('PTT_2').publicURL} alt="" />
          </div>
        </section>

        <section id="ptt-evolution" className="">
          <header className="title">
            <h1>PTT Evolution</h1>
          </header>
          <div className="flex">
            <div className="flex-child ptt-evo-1">
              <img src={getImage('PTT_button_1').publicURL} alt="" />
              <p>Initial PTT Button Design</p>
            </div>
            <div className="flex-child ptt-evo-2">
              <video poster={getImage('PTT_button_2_s').publicURL} autoPlay muted loop>
                <source src={getVideo('PTT_button_2').publicURL} type="video/mp4" />
              </video>
              <p>After Usability Testing</p>
            </div>
            <div className="flex-child ptt-evo-3">
              <video poster={getImage('PTT_button_3_s').publicURL} autoPlay muted loop>
                <source src={getVideo('PTT_button_3').publicURL} type="video/mp4" />
              </video>
              <p>Update For Android Marshmallow</p>
            </div>
          </div>
        </section>

        <section id="design-process" className="">
          <header className="title">
            <h1>Design Process</h1>
          </header>
          <div className="forms">
            <img src={getImage('PTT_3').publicURL} alt="" />
          </div>
        </section>

        <section id="final-design" className="darkgrey">
          <header className="title">
            <h1>Final Design</h1>
          </header>
          <div className="forms">
            <img src={getImage('PTT_4').publicURL} alt="" />
          </div>
        </section>

        <section id="phase_two" className="">
          <header className="title">
            <h1>Phase Two: Seamless PTT</h1>
          </header>
          <div className="one">
            <p>
              A seamless PTT experience consists of a convergence of PTT networks (LMR and LTE), where the user never has to choose which
              network to access.
            </p>
            <p>
              This requires a single, comprehensive user experience that can handle all network scenarios. LMR and LTE networks each have
              strengths and weaknesses. A seamless solution intelligently makes use of both networks to provide for the best outcome
              possible.
            </p>
          </div>
          <div className="forms">
            <img src={getImage('PTT_21').publicURL} alt="" />
          </div>
        </section>

        <section id="high-level-structure" className="grey">
          <header className="title">
            <h1>High Level Structure</h1>
          </header>
          <div className="forms">
            <img src={getImage('PTT_22').publicURL} alt="" />
          </div>
        </section>

        <section id="information-architecture" className="">
          <header className="title">
            <h1>Information Architecture</h1>
          </header>
          <div className="forms">
            <img src={getImage('PTT_24_text_2').publicURL} alt="" />
            <a href="#gallery1" className="mark mark1" onClick={e => openPhotoSwipe(e, '1')}>
              <div className="glow"></div>
              <div className="shape"></div>
            </a>
            <a href="#gallery1" className="mark mark2" onClick={e => openPhotoSwipe(e, '1')}>
              <div className="glow"></div>
              <div className="shape"></div>
            </a>
            <a href="#gallery2" className="mark mark3" onClick={e => openPhotoSwipe(e, '2')}>
              <div className="glow"></div>
              <div className="shape"></div>
            </a>
            <a href="#gallery3" className="mark mark4" onClick={e => openPhotoSwipe(e, '3')}>
              <div className="glow"></div>
              <div className="shape"></div>
            </a>
            <a href="#gallery4" className="mark mark5" onClick={e => openPhotoSwipe(e, '4')}>
              <div className="glow"></div>
              <div className="shape"></div>
            </a>
            <a href="#gallery5" className="mark mark6" onClick={e => openPhotoSwipe(e, '5')}>
              <div className="glow"></div>
              <div className="shape"></div>
            </a>
          </div>
        </section>
        <PhotoSwipe isOpen={isOpen === '1'} items={gallery1Items} options={options} onClose={handleClose} />
        <PhotoSwipe isOpen={isOpen === '2'} items={gallery2Items} options={options} onClose={handleClose} />
        <PhotoSwipe isOpen={isOpen === '3'} items={gallery3Items} options={options} onClose={handleClose} />
        <PhotoSwipe isOpen={isOpen === '4'} items={gallery4Items} options={options} onClose={handleClose} />
        <PhotoSwipe isOpen={isOpen === '5'} items={gallery5Items} options={options} onClose={handleClose} />
        <section id="stories" className="stories">
          <header className="title title-red">
            <h1>Final Design</h1>
          </header>
          <section className="story story1">
            <div className="stories_text">
              <h2>Overview</h2>
              <p>
                There are two main tabs: TalkGroups and Private PTT. Title of selected tab will be shown, so user can easily understand
                which tab they are on. TalkGroups tab is the default.{' '}
              </p>
            </div>
            <div className="story_wrapper">
              <div id="hand1" className="hand">
                <img src={getImage('PTT_phone_1').publicURL} alt="" />
              </div>
            </div>
          </section>
          <section className="story story2">
            <div className="stories_text">
              <h2>TalkGroups</h2>
              <p>
                One favorites zone. The favorites zone contains frequently used entries from the LMR zones or from the BB-only zone. The
                favorites zone is defined by the Denali user.
              </p>
              <p>One or more LMR zones that are defined by RM in the codeplug. These zones contain only LMR TGs and interoperable TGs.</p>
              <p>One BB-only zone that contains only BB-only TGs and interoperable TGs. </p>
            </div>

            <div className="story_wrapper">
              <div id="hand2" className="hand">
                <img src={getImage('PTT_phone_2_forever-1').publicURL} alt="" />
              </div>
            </div>
          </section>
          <section className="story story3">
            <div className="stories_text">
              <h2>Private PTT</h2>
              <p>Allow users to have 1:1 or 1:X private PTT session with contacts.</p>
              <p>
                If the contact is a converged member, which has both Broadband and LMR capability, it will always try Broadband PTT session
                first.
              </p>
            </div>
            <div className="story_wrapper">
              <div id="hand3" className="hand">
                <img src={getImage('PTT_phone_3').publicURL} alt="" />
              </div>
            </div>
          </section>
          <section className="story story4">
            <div className="stories_text">
              <h2>Top Display</h2>
              <p>
                Denali top display strategy is including behavior of Tx/Rx LED, top display left color bank, top display right color bank
                and main LED bank, and the interaction between Denali control top with main front display as well.
              </p>
              <a href="/topdisplay/">See more details</a>
            </div>
            <div className="story_wrapper">
              <div id="hand4" className="hand">
                <img src={getImage('PTT_phone_4').publicURL} alt="" />
              </div>
            </div>
          </section>
        </section>

        <section css={demoStyles}>
          <a href="#" css={jumpLinkStyles}>
            <img src={UpArrow} alt="Jump to the top" />
          </a>
          {projectDetails &&
            projectDetails.map(contentType => {
              if (contentType.__typename === 'ContentfulProjectDemo') {
                return <Demo key={contentType.id} url={contentType.url} title={contentType.title} linkType={contentType.linkType} />
              }
            })}
        </section>
      </div>
    </Layout>
  )
}

pttTemplate.propTypes = propTypes

export default pttTemplate

export const pageQuery = graphql`
  query($id: String!) {
    allFile(filter: { relativeDirectory: { eq: "ptt" } }) {
      nodes {
        name
        publicURL
        sourceInstanceName
      }
    }
    contentfulProject(id: { eq: $id }) {
      id
      title
      description {
        description
      }
    }
  }
`
